<template>
  <v-container fluid>
    <v-card class="mb-5">
      <h1>HISTÓRICO COMUNIONES</h1>
      <v-divider class="my-1"></v-divider>
      <v-simple-table id="totalComuniones" style="max-width: 1000px;"
      data-cols-width="10,10,13,10,10,15,10,10,10,10,10,10,10">
        <template v-slot:default>
          <thead>
            <tr>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="6" class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);">GENERALES</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="7" class="text-center" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);">ESPACIOS</th>
            </tr>
            <tr>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">AÑO</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">Nº COM</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">ADULTOS</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">NIÑOS</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">TOTAL</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base); border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">AV ADULTOS</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); border-left: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">PISCIS</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">HOTEL</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">LALANNE</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">LAUS</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">VDV</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">CAT ARA</th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"	data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base); border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">CAT CAT</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in resumen" :key="index">
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.fecha }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.comuniones }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.nAdultos }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.nNinos }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.total }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.avAdultos.toFixed(2) }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.piscis }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.hotel }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.lalanne }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.laus }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.vdv }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.oAragon }}</td>
              <td :data-b-b-s="(index == resumen.length-1 ? 'thick' : '')" data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.oCat }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-divider></v-divider>
    <v-card class="mt-5">
      <h1>COMUNIONES POR HACER</h1>
      <v-divider class="my-1"></v-divider>
      <v-data-table
        fixed-header
        :items="eventos.filter(e => new Date(e.diaReal) >= new Date())"
        :headers="eventosHeaders"
        class="fixed-checkbox"
        item-key="idAdHoc"
        :single-select="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50, -1],
        }"
        checkbox-color="secondary"
        style="cursor:pointer"
        >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="eventos"
            v-model="inlineFilters"
          ></table-filters>
        </template>
        <template v-slot:item="{ item, index }">
          <tr @click="contextMenuEvento(item)">
            <td class="espaciador"></td>
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{ index+1 }}</td>
            <td style="white-space: nowrap !important;">{{ item.dia }}</td>
            <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{ item.year }}</td>
            <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{ item.horario }}</td>
            <td style="white-space: nowrap !important;padding-left: 40px !important;padding-right: 40px !important">{{ item.confirmada }}</td>
            <td style="white-space: nowrap !important;">{{ item.nino }}</td>
            <td style="white-space: nowrap !important;">{{ item.padres }}</td>
            <td style="white-space: nowrap !important;">{{ item.espacio }}</td>
            <td style="white-space: nowrap !important;">{{ item.salon }}</td>
            <td style="white-space: nowrap !important;">{{ item.excl }}</td>
            <td style="white-space: nowrap !important;">{{ item.adultos }}</td>
            <td style="white-space: nowrap !important;">{{ item.ninos }}</td>
            <td style="white-space: nowrap !important;">{{ item.total }}</td>
            <td style="white-space: nowrap !important;">{{ item.aperitivo }}</td>
            <td style="white-space: nowrap !important;">{{ item.breveDato }}</td>
            <td style="white-space: nowrap !important;text-align: center">{{ item.fianza }}</td>
            <td style="white-space: nowrap !important;text-align: center">{{ item.facturaFianza }}</td>
            <td style="white-space: nowrap !important;text-align: center">{{ item.presupuesto }}</td>
            <td style="white-space: nowrap !important;">{{ item.origenReserva }}</td>
            <td style="white-space: nowrap !important;text-align: center">{{ item.habitaciones }}</td>
            <td style="white-space: nowrap !important;">{{ item.reserva }}</td>
            <td style="white-space: nowrap !important;">{{ item.fechaReserva }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-card class="mt-5">
      <h1>COMUNIONES EFECTUADAS</h1>
      <v-divider class="my-1"></v-divider>
      <v-data-table
        fixed-header
        :items="eventos.filter(e => new Date(e.diaReal) < new Date())"
        :headers="eventosHeaders2"
        class="fixed-checkbox"
        item-key="idAdHoc"
        :single-select="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [0, -1],
        }"
        checkbox-color="secondary"
        style="cursor:pointer"
        >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="eventos"
            v-model="inlineFilters2"
          ></table-filters>
        </template>
        <template v-slot:item="{ item, index }">
          <tr @click="contextMenuEvento(item)">
            <td class="espaciador"></td>
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{ index+1 }}</td>
            <td style="white-space: nowrap !important;">{{ item.dia }}</td>
            <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{ item.year }}</td>
            <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{ item.horario }}</td>
            <td style="white-space: nowrap !important;">{{ item.nino }}</td>
            <td style="white-space: nowrap !important;">{{ item.padres }}</td>
            <td style="white-space: nowrap !important;">{{ item.feedback }}</td>
            <td style="white-space: nowrap !important;">{{ item.espacio }}</td>
            <td style="white-space: nowrap !important;">{{ item.salon }}</td>
            <td style="white-space: nowrap !important;">{{ item.excl }}</td>
            <td style="white-space: nowrap !important;">{{ item.adultos }}</td>
            <td style="white-space: nowrap !important;">{{ item.ninos }}</td>
            <td style="white-space: nowrap !important;">{{ item.total }}</td>
            <td style="white-space: nowrap !important;">{{ item.aperitivo }}</td>
            <td style="white-space: nowrap !important;">{{ item.breveDato }}</td>
            <td style="white-space: nowrap !important;text-align: center">{{ item.fianza }}</td>
            <td style="white-space: nowrap !important;text-align: center">{{ item.facturaFianza }}</td>
            <td style="white-space: nowrap !important;text-align: center">{{ item.presupuesto }}</td>
            <td style="white-space: nowrap !important;">{{ item.origenReserva }}</td>
            <td style="white-space: nowrap !important;text-align: center">{{ item.habitaciones }}</td>
            <td style="white-space: nowrap !important;">{{ item.reserva }}</td>
            <td style="white-space: nowrap !important;">{{ item.fechaReserva }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-card class="mt-5">
      <h1>COMUNIONES PERDIDAS</h1>
      <v-divider></v-divider>
      <v-data-table fixed-header :items="anuladas" :headers="eventosHeaders3" class="fixed-checkbox" item-key="idAdHoc"
        :single-select="true" :loading="loading" :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [0, -1],
            }" checkbox-color="secondary" style="cursor:pointer">
        <template v-slot:body.prepend="{ headers }">
          <table-filters :headers="headers" :items="anuladas" v-model="inlineFilters3"></table-filters>
        </template>
        <template v-slot:item="{ item, index }">
          <tr @click="contextMenuEvento(item)">
            <td class="espaciador"></td>
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
              index+1 }}</td>
            <td style="white-space: nowrap !important;">{{ item.dia }}</td>
            <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
              item.year }}</td>
            <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
              item.horario }}</td>
            <td style="white-space: nowrap !important;padding-left: 40px !important;padding-right: 40px !important">{{
              item.confirmada }}</td>
            <td style="white-space: nowrap !important;">{{ item.perdida }}</td>
            <td style="white-space: nowrap !important;">{{ item.motivoPerdida }}</td>
            <td style="white-space: nowrap !important;">{{ item.nino }}</td>
            <td style="white-space: nowrap !important;">{{ item.espacio }}</td>
            <td style="white-space: nowrap !important;">{{ item.salon }}</td>
            <td style="white-space: nowrap !important;">{{ item.adultos }}</td>
            <td style="white-space: nowrap !important;">{{ item.ninos }}</td>
            <td style="white-space: nowrap !important;">{{ item.total }}</td>
            <td style="white-space: nowrap !important;">{{ item.tipoComunion }}</td>
            <td style="white-space: nowrap !important;">{{ item.origenReserva }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    
    <div
    style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000"
    bottom
    class="d-flex mt-2 ms-auto">
      <v-btn
        color="primary"
        fab
        @click="exportar"
        large
        class="me-4"
      >
        <v-icon>mdi-microsoft-excel</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        fab
        v-if="$root.acceso('CREAR_EVENTO')"
        @click="addEvento"
        large
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <NewAdHoc ref="confirm"></NewAdHoc>
    <SetObjetivo ref="confirmYear"></SetObjetivo>
  </v-container>
  </template>
  
  <script>
  import { parseDate, perColumnFilter } from "@/utils";
  
  export default {
    components: {
      SetObjetivo: () => import("@/components/SetObjetivo.vue"),
      NewAdHoc: () => import("@/components/NewAdHoc.vue"),
      TableFilters: () => import("@/components/TableFilters.vue"),
    },
    props: {
      all: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        inlineFilters: {
          idEvento: this.$route.query.filter
            ? `=${this.$route.query.filter}`
            : null,
        },
        inlineFilters2: {
          idEvento: this.$route.query.filter
            ? `=${this.$route.query.filter}`
            : null,
        },
        inlineFilters3: {
          idEvento: this.$route.query.filter
            ? `=${this.$route.query.filter}`
            : null,
        },
        eventos: [],
        anuladas: [],
        loading: false,
        resumen: [
        {
          fecha:2017,
          comuniones:112,
          nAdultos:3449,
          nNinos:879,
          total:4328,
          avAdultos:31,
          piscis:31,
          hotel:31,
          lalanne:7,
          laus:0,
          vdv:0,
          oAragon:3,
          oCat:0,
        },
        {
          fecha:2018,
          comuniones:81,
          nAdultos:2417,
          nNinos:630,
          total:3047,
          avAdultos:30,
          piscis:26,
          hotel:42,
          lalanne:7,
          laus:0,
          vdv:2,
          oAragon:4,
          oCat:0,
        },
        {
          fecha:2019,
          comuniones:73,
          nAdultos:2440,
          nNinos:674,
          total:3114,
          avAdultos:33,
          piscis:18,
          hotel:42,
          lalanne:6,
          laus:2,
          vdv:2,
          oAragon:3,
          oCat:0,
        },
        {
          fecha:2020,
          comuniones:28,
          nAdultos:800,
          nNinos:260,
          total:1060,
          avAdultos:29,
          piscis:3,
          hotel:19,
          lalanne:2,
          laus:2,
          vdv:0,
          oAragon:2,
          oCat:0,
        },
        {
          fecha: 2021,
          comuniones: 49,
          nAdultos: 986,
          nNinos: 222,
          total: 1208,
          avAdultos: 20,
          piscis: 13,
          hotel: 26,
          lalanne: 4,
          laus: 1,
          vdv: 1,
          oAragon: 4,
          oCat: 0,
        }
        ],
      };
    },
    computed: {
      eventosHeaders() {
        return [
                { text: "", class: "text-no-wrap sticky-header text-center espaciador", sortable: false, align: "center",},
                { text: "Nº", class: "text-no-wrap sticky-header text-center espaciador", },
                { text: "DÍA", class: "text-no-wrap sticky-header text-center espaciador", value: "dia", dataType: "select", align: "center" },
                { text: "AÑO", class: "text-no-wrap sticky-header text-center espaciador", value: "year", dataType: "select", align: "center" },
                { text: "Hº", class: "text-no-wrap sticky-header text-center espaciador", value: "horario", dataType: "select", align: "center" },
                { text: "CONF", class: "text-no-wrap sticky-header text-center espaciador", value: "confirmada", dataType: "select", align: "center" },
                { text: "COMULGANTE", class: "text-no-wrap sticky-header text-center espaciador", value: "nino" },
                { text: "PADRES", class: "text-no-wrap sticky-header text-center espaciador", value: "padres" },
                { text: "ESPACIO", class: "text-no-wrap sticky-header text-center espaciador", value: "espacio", dataType: "select" },
                { text: "SALÓN", class: "text-no-wrap sticky-header text-center espaciador", value: "salon" },
                { text: "EXCLUSIVO", class: "text-no-wrap sticky-header text-center espaciador", value: "excl", align: "center", dataType: "select" },
                { text: "ADULTOS", class: "text-no-wrap sticky-header text-center espaciador", value: "adultos" },
                { text: "NIÑOS", class: "text-no-wrap sticky-header text-center espaciador", value: "ninos" },
                { text: "TOTAL", class: "text-no-wrap sticky-header text-center espaciador", value: "total" },
                { text: "APERITIVO", class: "text-no-wrap sticky-header text-center espaciador", value: "aperitivo", dataType: "select" },
                { text: "BREVE DATO", class: "text-no-wrap sticky-header text-center espaciador", value: "breveDato" },
                { text: "PAGO FIANZA", class: "text-no-wrap sticky-header text-center espaciador", value: "fianza", dataType: "select" },
                { text: "FACTURA FIANZA", class: "text-no-wrap sticky-header text-center espaciador", value: "facturaFianza", dataType: "select" },
                { text: "PRESUPUESTO", class: "text-no-wrap sticky-header text-center espaciador", value: "presupuesto", dataType: "select" },
                { text: "ORIGEN RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "origenReserva", dataType: "select" },
                { text: "HABITACIONES", class: "text-no-wrap sticky-header text-center espaciador", value: "habitaciones", dataType: "select" },
                { text: "QUIEN RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "reserva", dataType: "select" },
                { text: "FECHA RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "fechaReserva", dataType: "select" },
            ]
          .filter(
            (h) =>
              (h.acceso == null || this.$root.acceso(h.acceso))
          )
          .map((header) => ({
            class: "text-no-wrap sticky-header",
            cellClass: "pa-1 text-no-wrap",
            filter: (value) =>
              perColumnFilter(
                value,
                this.inlineFilters[header.value],
                header.dataType
              ),
            dataType: "text",
            ...header,
          }));
      },
      eventosHeaders2() {
        return [
                { text: "", class: "text-no-wrap sticky-header text-center espaciador", sortable: false, align: "center",},
                { text: "Nº", class: "text-no-wrap sticky-header text-center espaciador", },
                { text: "DÍA", class: "text-no-wrap sticky-header text-center espaciador", value: "dia", dataType: "select", align: "center" },
                { text: "AÑO", class: "text-no-wrap sticky-header text-center espaciador", value: "year", dataType: "select", align: "center" },
                { text: "Hº", class: "text-no-wrap sticky-header text-center espaciador", value: "horario", dataType: "select", align: "center" },
                // { text: "CONF", class: "text-no-wrap sticky-header text-center espaciador", value: "confirmada", dataType: "select", align: "center" },
                { text: "COMULGANTE", class: "text-no-wrap sticky-header text-center espaciador", value: "nino" },
                { text: "PADRES", class: "text-no-wrap sticky-header text-center espaciador", value: "padres" },
                { text: "VALORACIÓN", dataType: "select", value: "feedback", class: "text-no-wrap sticky-header" },
                { text: "ESPACIO", class: "text-no-wrap sticky-header text-center espaciador", value: "espacio", dataType: "select" },
                { text: "SALÓN", class: "text-no-wrap sticky-header text-center espaciador", value: "salon" },
                { text: "EXCLUSIVO", class: "text-no-wrap sticky-header text-center espaciador", value: "excl", align: "center", dataType: "select" },
                { text: "ADULTOS", class: "text-no-wrap sticky-header text-center espaciador", value: "adultos" },
                { text: "NIÑOS", class: "text-no-wrap sticky-header text-center espaciador", value: "ninos" },
                { text: "TOTAL", class: "text-no-wrap sticky-header text-center espaciador", value: "total" },
                { text: "APERITIVO", class: "text-no-wrap sticky-header text-center espaciador", value: "aperitivo", dataType: "select" },
                { text: "BREVE DATO", class: "text-no-wrap sticky-header text-center espaciador", value: "breveDato" },
                { text: "PAGO FIANZA", class: "text-no-wrap sticky-header text-center espaciador", value: "fianza", dataType: "select" },
                { text: "FACTURA FIANZA", class: "text-no-wrap sticky-header text-center espaciador", value: "facturaFianza", dataType: "select" },
                { text: "PRESUPUESTO", class: "text-no-wrap sticky-header text-center espaciador", value: "presupuesto", dataType: "select" },
                { text: "ORIGEN RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "origenReserva", dataType: "select" },
                { text: "HABITACIONES", class: "text-no-wrap sticky-header text-center espaciador", value: "habitaciones", dataType: "select" },
                { text: "QUIEN RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "reserva", dataType: "select" },
                { text: "FECHA RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "fechaReserva", dataType: "select" },
            ]
          .filter(
            (h) =>
              (h.acceso == null || this.$root.acceso(h.acceso))
          )
          .map((header) => ({
            class: "text-no-wrap sticky-header",
            cellClass: "pa-1 text-no-wrap",
            filter: (value) =>
              perColumnFilter(
                value,
                this.inlineFilters2[header.value],
                header.dataType
              ),
            dataType: "text",
            ...header,
          }));
      },
      eventosHeaders3() {
        return [
          { text: "", class: "text-no-wrap sticky-header text-center espaciador", sortable: false, align: "center",},
          { text: "Nº", class: "text-no-wrap sticky-header text-center espaciador", },
          { text: "DÍA", class: "text-no-wrap sticky-header text-center espaciador", value: "dia", dataType: "select", align: "center" },
          { text: "AÑO", class: "text-no-wrap sticky-header text-center espaciador", value: "year", dataType: "select", align: "center" },
          { text: "Hº", class: "text-no-wrap sticky-header text-center espaciador", value: "horario", dataType: "select", align: "center" },
          { text: "CONF", class: "text-no-wrap sticky-header text-center espaciador", value: "confirmada", dataType: "select", align: "center" },
          { text: "COMULGANTE", class: "text-no-wrap sticky-header text-center espaciador", value: "nino" },
          { text: "PADRES", class: "text-no-wrap sticky-header text-center espaciador", value: "padres" },
          { text: "ESPACIO", class: "text-no-wrap sticky-header text-center espaciador", value: "espacio", dataType: "select" },
          { text: "SALÓN", class: "text-no-wrap sticky-header text-center espaciador", value: "salon" },
          { text: "ADULTOS", class: "text-no-wrap sticky-header text-center espaciador", value: "adultos" },
          { text: "NIÑOS", class: "text-no-wrap sticky-header text-center espaciador", value: "ninos" },
          { text: "TOTAL", class: "text-no-wrap sticky-header text-center espaciador", value: "total" },
          { text: "TIPO COMUNIÓN", class: "text-no-wrap sticky-header text-center espaciador", value: "tipoComunion", dataType: "select" },
          { text: "ORIGEN RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "origenReserva", dataType: "select" },
        ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          class: "text-no-wrap sticky-header",
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters2[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
      },
    },
    mounted() {
    //   if (!(!!(this.$store.getters.getTokenPayload.permisos & this.$store.getters.getPermisos['RESUMEN_RENTABILIDAD']))) this.$router.push({ path: "/" });
      this.getEventos();
    },
    methods: {
      exportar(){
          var libroComunion = TableToExcel.tableToBook(document.getElementById("totalComuniones"), {
            sheet: {
              name: "TOTAL COMUNIONES"
            }
          })

          var tabla = document.createElement("table");
          tabla.innerHTML = '<thead><tr>'+
            '<th data-b-a-s="thick">Nº</th>'+
            '<th data-b-a-s="thick">DÍA</th>'+
            '<th data-b-a-s="thick">AÑO</th>'+
            '<th data-b-a-s="thick">Hº</th>'+
            '<th data-b-a-s="thick">CONF</th>'+
            '<th data-b-a-s="thick">NOMBRE COMULGANTE</th>'+
            '<th data-b-a-s="thick">PADRES</th>'+
            '<th data-b-a-s="thick">TLF PADRE</th>'+
            '<th data-b-a-s="thick">TLF MADRE</th>'+
            '<th data-b-a-s="thick">EMAIL PADRE</th>'+
            '<th data-b-a-s="thick">EMAIL MADRE</th>'+
            '<th data-b-a-s="thick">ESPACIO</th>'+
            '<th data-b-a-s="thick">SALÓN</th>'+
            '<th data-b-a-s="thick">EXCLUSIVO</th>'+
            '<th data-b-a-s="thick">ADULTOS</th>'+
            '<th data-b-a-s="thick">NIÑOS</th>'+
            '<th data-b-a-s="thick">TOTAL</th>'+
            '<th data-b-a-s="thick">APERITIVO</th>'+
            '<th data-b-a-s="thick">BREVE DATO</th>'+
            '<th data-b-a-s="thick">PAGO FIANZA</th>'+
            '<th data-b-a-s="thick">FACTURA FIANZA</th>'+
            '<th data-b-a-s="thick">PRESUPUESTO</th>'+
            '<th data-b-a-s="thick">ORIGEN RESERVA</th>'+
            '<th data-b-a-s="thick">HABITACIONES</th>'+
            '<th data-b-a-s="thick">QUIEN RESERVA</th>'+
            '<th data-b-a-s="thick">FECHA RESERVA</th>'+
            '</tr></thead>';
          var tbody = document.createElement("tbody")
          for (let i = 0; i < this.eventos.length; i++) {
            var tr = document.createElement("tr");
            tr.innerHTML = '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ (i+1) +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].dia +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].year +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].horario +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].confirmada +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].nino +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].padres +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].tlfPadre +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].tlfMadre +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].emailPadre +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].emailMadre +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].espacio +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ (this.eventos[i].salon || '') +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].excl +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].adultos +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].ninos +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].total +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ (this.eventos[i].aperitivo || '') +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ (this.eventos[i].breveDato || '') +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].fianza +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].facturaFianza +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].presupuesto +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ (this.eventos[i].origenReserva || '') +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].habitaciones +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ (this.eventos[i].reserva || '') +'</td>' +
            '<td data-a-h="center" data-a-v="center" data-a-wrap="false">'+ this.eventos[i].fechaReserva +'</td>';
            tbody.appendChild(tr)
          }
          tabla.appendChild(tbody)
          tabla.setAttribute("data-cols-width", "5,12,10,6,6,25,25,25,25,25,27,13,15,15,10,10,10,17,30,15,15,15,15,15,20,17")
          TableToExcel.tableToSheet(libroComunion, tabla, {sheet:{name:"LISTADO COMUNIONES"}});
        TableToExcel.save(libroComunion, "COMUNIONES - EXPORTADO EL " + (new Date().toLocaleDateString('default', { weekday : 'short'}).toUpperCase() + ' - ' + (('0' + new Date().getDate()).slice(-2)) + (new Date().toLocaleString('default', { month: 'short' }).toUpperCase())) + " - " + new Date().getFullYear() + ".xlsx")
      },
      async cambiarObjetivo(year){
        const dat = await this.$refs.confirmYear.open('Cambiar objetivo ' + year.fecha)
        if (dat) {
          try {
            await axios({
              method: 'POST',
              url: `${process.env.VUE_APP_API_URL}/objetivo/comunion/${year.fecha}`,
              data: {
                  items: dat,
              }
            })
            this.$root.$emit("snack", "Se han guardado los cambios correctamente");
            this.getEventos()
          } catch (err){
            this.saving = false;
            this.$root.$emit("snack", "No se han podido guardar los cambios");
          }
        }
      },
      download_table_as_csv(table_id, separator = ',') {
        var rows = document.querySelectorAll('table#' + table_id + ' tr');
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll('td, th');
            for (var j = 0; j < cols.length; j++) {
                var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                data = data.replace(/"/g, '""');
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join('\n');
        var filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
        var link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      parseDate,
      async addEvento() {
        const dat = await this.$refs.confirm.open();
        if (dat == "boda") { this.$router.push({ name: "datosBoda" }) }
        else if (dat == "comunion") { this.$router.push({ name: "datosComunion" }) }
      },
      async getEventos() {
        this.loading = true;
        const { data } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/listado/comunion`,
        });
  
        this.eventos = data.map((m) => {
          return m;
        });

        const res = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/listado/comunionanulada`,
        });
        
        this.anuladas = res.data.map((m) => {
          return m;
        });
  
        const resumen = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/listado/comuniontotal`,
        });
        
        this.resumen = [...this.resumen,...resumen.data];
        this.loading = false;
      },
      contextMenuEvento(item){
        this.$router.push({ name: 'datosComunion', params: { idAdHoc: item.idAdHoc }})
      }
    },
  };
  </script>
<style>
.espaciador{
  padding:5px !important
}
</style>